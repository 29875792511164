import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import {
  Button,
  Icon,
  IconType,
  Message,
  MessageController,
  PageContainer,
  useMessages,
} from 'components';
import {useTranslation} from 'react-i18next';
import {MyTenantsDocument} from 'generated/graphql-schema';
import {useQuery} from '@apollo/client';
import {Loader} from 'components/Loader';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {ErrorUtils} from '@dreamteamos/frontend-lib';
import {INTEGRATION_STATIC_ASSETS, StaticContent} from '@dreamteamos/common';
import {config, LINKED_IN_CONVERSION_ID} from 'config';
import {ROUTES} from 'config/routes';

const BG_STYLE: React.CSSProperties = {
  backgroundImage: `url(${StaticContent.integrationAssetUrl({
    asset: INTEGRATION_STATIC_ASSETS.LoginBackground,
    staticContentBaseUrl: config.getStaticContentBaseUrl(),
  })})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};
export const Login: React.FC = () => {
  const {t} = useTranslation(['common', 'pages', 'networking']);
  const navigate = useNavigate();
  const messages = useMessages();

  const {data: tenants, loading} = useQuery(MyTenantsDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: error => {
      const messaging = ErrorUtils.getErrorMessaging({
        error,
        title: t('networking:retreiving-tenant-info-failed'),
      });
      messaging.logMessage();
      messages.push(messaging.getMessage());
    },
  });
  const [selectedTenantUrl, setSelectedTenantUrl] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (tenants) {
      if (tenants.myTenants.edges.length === 0) {
        (window as any)?.lintrk('track', {
          conversion_id: LINKED_IN_CONVERSION_ID,
        });
        navigate({pathname: ROUTES.SIGN_UP});
      } else if (tenants.myTenants.edges.length === 1) {
        window.location.href = tenants.myTenants.edges[0].node.endpoint;
      }
    }
  }, [navigate, tenants, tenants?.myTenants.edges]);

  const onSelectedChange = useCallback((e: string) => {
    setSelectedTenantUrl(e);
  }, []);

  const handleContinue = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!selectedTenantUrl) {
        return;
      }
      window.location.href = selectedTenantUrl;
    },
    [selectedTenantUrl]
  );

  const items = tenants?.myTenants.edges
    .map(e => e.node)
    .map(node => ({
      title: node.name,
      value: node.endpoint,
    }));

  return (
    <PageContainer
      className="bg-gray-50 flex items-center justify-center p-14"
      title={t('pages:tenant-login.title')}
      style={BG_STYLE}
    >
      <div className="bg-white flex flex-col p-12 w-[25rem] max-h-[40rem]">
        <div>
          <Icon variant={IconType.KATA} size={2} />
        </div>
        <div className="flex flex-col justify-center mt-4">
          <h1 className="font-medium leading-9 text-3xl text-gray-800">
            {t('pages:tenant-login.welcome')}
          </h1>
          <div className="text-base text-gray-800 mt-2">
            {t('pages:tenant-login.select-workspace')}
          </div>
        </div>
        <MessageController
          render={args => (
            <Message className="mt-3" {...args} onClose={undefined} />
          )}
        />
        {loading ? (
          <div className="w-full h-56 flex items-center justify-center">
            <Loader size={34} />
          </div>
        ) : (
          <div className="shrink overflow-y-scroll scrollbar-gray mt-3 -mx-2 min-h-[14rem]">
            <form className="flex flex-col items-stretch cursor-pointer space-y-4 px-2">
              {items?.map(item => {
                const selected = item.value === selectedTenantUrl;
                return (
                  <div
                    key={item.value}
                    className={classNames(
                      'flex flex-col border rounded-md border-solid border-1 px-3 py-2 overflow-clip',
                      selected
                        ? 'border-bondi-600 bg-bondi-300'
                        : 'border-gray-100 hover:bg-gray-50'
                    )}
                    onClick={() => onSelectedChange(item.value)}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <span
                        className={classNames(
                          'text-sm font-medium text-gray-800'
                        )}
                      >
                        {item.title}
                      </span>
                      <input
                        type="radio"
                        checked={selected}
                        className={classNames('', selected && 'bg-white')}
                        onChange={() => onSelectedChange(item.value)}
                      />
                    </div>
                    <span
                      className={classNames(
                        'text-sm italic text-gray-600 truncate'
                      )}
                    >
                      {item.value}
                    </span>
                  </div>
                );
              })}
            </form>
          </div>
        )}

        <Button
          className="mt-10"
          type="button"
          variant="primary-alt"
          onClick={handleContinue}
          disabled={!selectedTenantUrl}
        >
          {t('common:continue')}
        </Button>
      </div>
    </PageContainer>
  );
};
