export enum SLACK_STATIC_ASSETS {
  ActivityBanner = 'activity_banner.png',
  FlashBanner = 'flash_banner.png',
  FocusBanner = 'focus_banner.png',
  ActivityBanner2 = 'activity_banner2.png',
  FlashBanner2 = 'flash_banner2.png',
  FocusBanner2 = 'focus_banner2.png',
  ActivityBanner3 = 'activity_banner3.png',
  FlashBanner3 = 'flash_banner3.png',
  FocusBanner3 = 'focus_banner3.png',
  InsightsBanner3 = 'insights_banner3.png',
  GitHubMark = 'github_mark_neutral.png',
  LinearIcon = 'linear_icon.png',
  MilestoneIcon = 'milestone_icon.png',
  ActionItemIcon = 'action_item_icon.png',
  ProjectIcon = 'project_icon.png',
  DailyUpdateBanner = 'daily_update_banner.png',
  TeamsLaunchBanner = 'teams_banner.png',
}

export enum INTEGRATION_STATIC_ASSETS {
  KataLinearDispayIcon = 'linear_kata_round_square.png',
  LoginBackground = 'login-background.svg',
}

export enum FE_STATIC_ASSETS {
  DemoProjectIcon = 'demo_icon.png',
}

export enum EMAIL_STATIC_ASSETS {
  AvatarPlaceholder = 'avatar-email.png',
  Background = 'blue-bg.png',
  DecisionPill = 'decision-email.png',
  DemoPill = 'demo-email.png',
  GroupIcon = 'group-email.png',
  KataFooterLogo = 'kata-footer-logo.png',
  KataLogo = 'KATA-logo.png',
  LoomThumbnail = 'loom-thumbnail.png',
  ProjectIcon = 'project-email.png',
  ToolsIcon = 'tools-email.png',
  WorkedOnIcon = 'worked-on.png',
}

export class StaticContent {
  static slackAssetUrl({
    asset,
    staticContentBaseUrl,
  }: {
    staticContentBaseUrl: string;
    asset: SLACK_STATIC_ASSETS;
  }): string {
    return new URL(`slack/${asset}`, staticContentBaseUrl).href;
  }

  static integrationAssetUrl({
    asset,
    staticContentBaseUrl,
  }: {
    staticContentBaseUrl: string;
    asset: INTEGRATION_STATIC_ASSETS;
  }): string {
    return new URL(`integrations/${asset}`, staticContentBaseUrl).href;
  }

  static feAssetUrl({
    asset,
    staticContentBaseUrl,
  }: {
    staticContentBaseUrl: string;
    asset: FE_STATIC_ASSETS;
  }): string {
    return new URL(`fe/${asset}`, staticContentBaseUrl).href;
  }

  static emailAssetUrl({
    asset,
    staticContentBaseUrl,
  }: {
    staticContentBaseUrl: string;
    asset: EMAIL_STATIC_ASSETS;
  }): string {
    return new URL(`email/${asset}`, staticContentBaseUrl).href;
  }
}
