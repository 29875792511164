import React, {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {ModelState, TenantProgressDocument} from 'generated/graphql-schema';
import {useTranslation} from 'react-i18next';
import {PulsingKata} from 'components/Progress/PulsingKata';
import {
  ApolloErrorMessaging,
  ApolloErrorUtils,
} from '@dreamteamos/frontend-lib';
import {ErrorCodes} from '@dreamteamos/common';
import {Message, MessageController, useMessages} from 'components';
import {useSearchParams} from 'react-router-dom';

export interface SignUpProgressProps {}

export const SignUpProgress: React.FC<SignUpProgressProps> = _props => {
  const {t} = useTranslation(['pages', 'networking', 'common']);
  const messages = useMessages();
  const [searchParams] = useSearchParams();

  const {data} = useQuery(TenantProgressDocument, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 3000,
    skip: searchParams.has('test'),
    onError: error => {
      const code = ApolloErrorUtils.graphQLApplicationErrorCode(error);
      if (code === ErrorCodes.Common.NotFound) {
        return;
      }
      const messaging = new ApolloErrorMessaging({
        error,
        title: t('networking:retreiving-tenant-info-failed'),
      });
      messaging.logMessage();
      messages.push(messaging.getMessage());
    },
  });

  useEffect(() => {
    switch (data?.tenantProgress.state) {
      case ModelState.Active:
        window.location.replace(data.tenantProgress.endpoint);
        break;
      case ModelState.Creating:
        break;
      case ModelState.Deleting:
      case ModelState.PendingDelete:
      case ModelState.Updating:
      case ModelState.Hidden:
        console.warn(`Unhandled Tenant State: ${data?.tenantProgress.state}`);
        break;
      case undefined:
        break;
    }
  }, [data]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col items-center justify-center px-10">
        <div className="flex-1 flex items-center">
          <MessageController render={args => <Message {...args} />} />
        </div>
        <div className="flex flex-col items-center">
          <PulsingKata className="mb-4" />
          <div className="text-lg font-semibold">
            {data?.tenantProgress?.humorousMessage
              ? t('pages:sign-up-progress.progress-title', {
                  title: data.tenantProgress.humorousMessage,
                })
              : t('pages:sign-up-progress.progress-title-finishing')}
          </div>
          <div className="text-lg font-normal mt-2">
            {t('pages:sign-up-progress.progress-subtitle')}
          </div>
        </div>
        <div className="flex-1" />
      </div>
    </div>
  );
};
